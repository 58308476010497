import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Route, Routes } from "react-router-dom";


import Navbar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import Dashboard from './dashboard/Dashboard.js';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import logo from '../assets/images/logo.png';
import { Box, Drawer } from '@mui/material';
import ProductList from './products/ProductList.js';
import BuyersList from './buyers/BuyersList.js';
import InviteBuyers from './buyers/InviteBuyers.js';
import GeneralSettings from './settings/GeneralSettings.js';
import UpdateProfile from './settings/UpdateProfile.js';
import ChangePhoto from './settings/ChangePhoto.js';
import ChangePassword from './settings/ChangePassword.js';
import ChangeCenterAd from './settings/ChangeCenterAd.js';
import UpdateLiveData from './settings/UpdateLiveData.js';
import Queries from './customerSupport/Queries.js';
import Catalogues from './products/Catalogues.js';
import TagCategory from './products/TagCategory.js';
import Tags from './products/Tags.js';
import ProductLastUpdated from './products/ProductLastUpdated.js';
import Enquiries from './products/Enquiries.js';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Admin() {
    const [open, setOpen] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(true); // Add this line
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
  
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    if (!isLoggedIn) {
        navigate('/login'); // Redirect to login page if not logged in
        return null;
    }

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleMenuClose = () => {
        setAnchorEl(null);
      };
    
      const handleLogout = () => {
        console.log('Logout clicked');
        handleMenuClose();
        navigate('/login');
      };

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundColor: '#417690'
                }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        <IconButton onClick={handleDrawerToggle}>
                            <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
                        </IconButton>
                    </Typography>
                    <IconButton color="inherit" onClick={handleMenuClick}>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Sidebar sx={{ marginTop: 4 }} open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ marginTop: 4, paddingRight: 40, flexGrow: 1, p: 0, width: `calc(100% - ${open ? '240px' : '40px'})`, ml: `${open ? '200px' : '0px'}` }}>
                {/* Your main content goes here */}

                <Toolbar />
                <Routes>
                    <Route path="/dashboard/Dashboard" element={<Dashboard />} />
                    <Route path="/products/list" element={<ProductList />} />
                    <Route path="/buyers/list" element={<BuyersList />} />
                    <Route path="/buyers/invite" element={<InviteBuyers />} />
                    <Route path="/settings/generalSettings" element={<GeneralSettings />} />
                    <Route path="/settings/updateProfile" element={<UpdateProfile />} />
                    <Route path="/settings/changePhoto" element={<ChangePhoto />} />
                    <Route path="/settings/changePassword" element={<ChangePassword />} />
                    <Route path="/settings/changeCenterAd" element={<ChangeCenterAd />} />
                    <Route path="/settings/updateLiveData" element={<UpdateLiveData />} />
                    <Route path="/customerSupport/queries" element={<Queries />} />
                    <Route path="/products/catalogues" element={<Catalogues />} />
                    <Route path="/products/tagCategory" element={<TagCategory />} />
                    <Route path="/products/tags" element={<Tags />} />
                    <Route path="/products/productLastUpdated" element={<ProductLastUpdated />} />
                    <Route path="/products/enquiries" element={<Enquiries />} />
                </Routes>
            </Box>
        </>
    );
}

export default Admin;