import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Container, Box, TableCell, IconButton, Menu, MenuItem, Modal, Typography, Button, Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, TextField, Select, InputLabel, FormControl, MenuItem as MuiMenuItem} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const columns = (activePage, handleUploadClick, handleProductClick, handleStarClick) => [
    {
        name: "buyerName",
        label: "Buyer Name",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', fontSize: '16px', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "email",
        label: "Email",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', fontSize: '16px', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "phone",
        label: "Phone",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', fontSize: '16px', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "companyName",
        label: "Company Name",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', fontSize: '16px', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "action",
        label: "Action",
        options: {
            filter: false,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', fontSize: '16px', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value, tableMeta, updateValue) => {
                if (activePage === "Active Buyers") {
                    return (
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <IconButton onClick={() => handleUploadClick(tableMeta.rowData, 'Upload Excel Sheet')} sx={{ color: 'teal' }}>
                                <UploadFileIcon />
                            </IconButton>
                            <IconButton onClick={() => handleProductClick(tableMeta.rowData, 'Choose Products')} sx={{ color: 'teal' }}>
                                <ShoppingCartIcon />
                            </IconButton>
                            <IconButton onClick={() => handleStarClick(tableMeta.rowData, 'Recommendations')} sx={{ color: 'orange' }}>
                                <StarIcon />
                            </IconButton>
                            <IconButton onClick={() => handleActionClick(tableMeta.rowData, 'Liked Products')} sx={{ color: 'crimson' }}>
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton onClick={() => handleActionClick(tableMeta.rowData, 'Delete')} sx={{ color: 'red' }}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                } else if (activePage === "Re-Activation List") {
                    return (
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <IconButton onClick={() => handleReactivationActionClick(tableMeta.rowData, 'Resend Activation Link')} sx={{ color: 'teal' }}>
                                <MailOutlineIcon />
                            </IconButton>
                            <IconButton onClick={() => handleReactivationActionClick(tableMeta.rowData, 'Delete')} sx={{ color: 'red' }}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    );
                }
            }
        }
    }
];

// Dummy function for fetching buyers' data
const fetchBuyersData = () => {
    return [
        { buyerName: "Jane Doe", email: "jane.doe@testcorp.com", phone: "(555) 555-5559", companyName: "Acme Corp" },
        { buyerName: "Tom Cruise", email: "tom.cruise@testcorp.com", phone: "(555) 555-5560", companyName: "Top Gun Inc." },
        { buyerName: "Sam Smith", email: "sam.smith@testcorp.com", phone: "(555) 555-5561", companyName: "Music World" },
        { buyerName: "John Doe", email: "john.doe@testcorp.com", phone: "(555) 555-5562", companyName: "Corporate Solutions" },
    ];
};

// Dummy function for fetching reactivation list data
const fetchReactivationData = () => {
    return [
        { buyerName: "Peter Parker", email: "peter.parker@reactcorp.com", phone: "(555) 555-5563", companyName: "Spider Tech" },
        { buyerName: "Bruce Wayne", email: "bruce.wayne@reactcorp.com", phone: "(555) 555-5564", companyName: "Wayne Enterprises" },
    ];
};

// Mock API calls
const fetchCategories = async () => [{ id: 1, name: 'Category 1' }, { id: 2, name: 'Category 2' }];
const fetchSubCategories = async () => [{ id: 1, name: 'Sub-Category 1' }, { id: 2, name: 'Sub-Category 2' }];
const fetchMills = async () => [{ id: 1, name: 'Mill 1' }, { id: 2, name: 'Mill 2' }];
const fetchSizes = async () => [{ id: 1, name: 'Size 1' }, { id: 2, name: 'Size 2' }];
const fetchWidths = async () => [{ id: 1, name: 'Width 1' }, { id: 2, name: 'Width 2' }];
const fetchStyles = async () => [{ id: 1, name: 'Style 1' }, { id: 2, name: 'Style 2' }];
const fetchTypes = async () => [{ id: 1, name: 'Type 1' }, { id: 2, name: 'Type 2' }];
const fetchPatterns = async () => [{ id: 1, name: 'Pattern 1' }, { id: 2, name: 'Pattern 2' }];
const fetchShades = async () => [{ id: 1, name: 'Shade 1' }, { id: 2, name: 'Shade 2' }];
const fetchColors = async () => [{ id: 1, name: 'Color 1' }, { id: 2, name: 'Color 2' }];

// Dummy action handlers
const handleActionClick = (rowData, action) => {
    console.log("Action clicked for Active Buyers:", rowData, action);
};

const handleReactivationActionClick = (rowData, action) => {
    console.log("Action clicked for Re-Activation List:", rowData, action);
};

function BuyersList() {
    const [data, setData] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [activePage, setActivePage] = useState('Active Buyers');     
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [productModalOpen, setProductModalOpen] = useState(false);
    const [starModalOpen, setStarModalOpen] = useState(false);
    const [selectedBuyer, setSelectedBuyer] = useState(null);

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [mills, setMills] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [widths, setWidths] = useState([]);
    const [styles, setStyles] = useState([]);
    const [types, setTypes] = useState([]);
    const [patterns, setPatterns] = useState([]);
    const [shades, setShades] = useState([]);
    const [colors, setColors] = useState([]);

    useEffect(() => {
        if (activePage === 'Active Buyers') {
            const buyersData = fetchBuyersData();
            setData(buyersData);
        } else if (activePage === 'Re-Activation List') {
            const reactivationData = fetchReactivationData();
            setData(reactivationData);
        }
        // Fetch dropdown data from backend
        fetchDropdownData();
    }, [activePage]);

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleMenuItemClick = (page) => {
        setActivePage(page);
        handleMenuClose();
    };

    const handleUploadClick = (rowData) => {
        console.log("Upload Excel clicked:", rowData);
        setUploadModalOpen(true); 
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            console.log('Uploading file:', selectedFile.name);
        }
        setUploadModalOpen(false); // Close the modal after upload
    };

    const handleProductClick = (buyer) => {
        setSelectedBuyer(buyer);
        setProductModalOpen(true);
    };

    const fetchDropdownData = async () => {
        // Fetch data for each dropdown from backend APIs and set the state.
        setCategories(await fetchCategories());
        setSubCategories(await fetchSubCategories());
        setMills(await fetchMills());
        setSizes(await fetchSizes());
        setWidths(await fetchWidths());
        setStyles(await fetchStyles());
        setTypes(await fetchTypes());
        setPatterns(await fetchPatterns());
        setShades(await fetchShades());
        setColors(await fetchColors());
    };

    const handleStarClick = (buyer) => {
        setSelectedBuyer(buyer);
        setStarModalOpen(true);
    };

    const handleModalClose = () => {
        setUploadModalOpen(false);
        setProductModalOpen(false);
        setStarModalOpen(false);
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Box sx={{ width: '100%', height: '100%' }}>
                {/* Data Table */}
                <MUIDataTable
                    title={
                        <Box display="flex" alignItems="center">
                            <Box sx={{ fontSize: '1.24rem' }}>{activePage}</Box>
                            <IconButton onClick={handleMenuOpen} sx={{ ml: 1 }}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose} >
                                <MenuItem onClick={() => handleMenuItemClick('Active Buyers')} sx={{ backgroundColor: activePage === 'Active Buyers' ? 'lightblue' : 'inherit' }}>
                                    Active Buyers
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Re-Activation List')} sx={{ backgroundColor: activePage === 'Re-Activation List' ? 'lightblue' : 'inherit' }}>
                                    Re-Activation List
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                    data={data}
                    columns={columns(activePage, handleUploadClick, handleProductClick, handleStarClick)}
                    options={{ filterType: 'checkbox',responsive: 'standard', tableBodyHeight: 'calc(100vh - 64px - 48px)', tableBodyMaxHeight: 'calc(100vh - 64px - 48px)' }}
                />
            </Box>

            {/* Upload Modal */}
            <Modal open={uploadModalOpen} onClose={handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'left' }}>
                        Upload Excel File
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                        <TextField sx={{ width: '100%' }} variant="outlined" value={selectedFile ? selectedFile.name : ''} InputProps={{ readOnly: true }} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>                   
                    <Button component="label" sx={{ width: '40%', color: '#417690', backgroundColor: '#EDF4F7' }}>
                        Choose File
                        <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>  
                    <Button sx={{ width: '40%', color: '#417690', backgroundColor: '#EDF4F7' }} onClick={handleUpload}>
                        Upload
                    </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Product Modal */}
            <Modal open={productModalOpen} onClose={handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
                    <Typography variant="h6" component="h2">
                        Chosen Products
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        Buyer: {selectedBuyer?.buyerName}
                    </Typography>
                    <TextField
                        label="Tags"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button sx={{ width: '30%', color: '#417690', backgroundColor: '#EDF4F7' }}>Submit</Button>
                    </Box>

                    {/* Table with items, date, and action */}
                    <Table sx={{ mt: 3 }}>
                        <TableHead>
                            <TableRow>
                                <MuiTableCell>Items</MuiTableCell>
                                <MuiTableCell>Posted Date</MuiTableCell>
                                <MuiTableCell>Action</MuiTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <MuiTableCell>Sample Tag 1</MuiTableCell>
                                <MuiTableCell>{new Date().toLocaleDateString()}</MuiTableCell>
                                <MuiTableCell>
                                    <IconButton sx={{ color: 'red' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </MuiTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Modal>

            {/* Star Icon Modal */}
            <Modal open={starModalOpen} onClose={handleModalClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: '80%', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" component="h2">
                        Add to Recommendation
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Category</InputLabel>
                            <Select label="Category" value="">
                                {categories.map(category => (
                                    <MuiMenuItem key={category.id} value={category.name}>
                                        {category.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Sub-Category</InputLabel>
                            <Select label="Sub-Category" value="">
                                {subCategories.map(subCategory => (
                                    <MuiMenuItem key={subCategory.id} value={subCategory.name}>
                                        {subCategory.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Mill</InputLabel>
                            <Select label="Mill" value="">
                                {mills.map(mill => (
                                    <MuiMenuItem key={mill.id} value={mill.name}>
                                        {mill.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Size</InputLabel>
                            <Select label="Size" value="">
                                {sizes.map(size => (
                                    <MuiMenuItem key={size.id} value={size.name}>
                                        {size.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Width</InputLabel>
                            <Select label="Width" value="">
                                {widths.map(width => (
                                    <MuiMenuItem key={width.id} value={width.name}>
                                        {width.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>                           
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Style</InputLabel>
                            <Select label="Style" value="">
                                {styles.map(style => (
                                    <MuiMenuItem key={style.id} value={style.name}>
                                        {style.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Type</InputLabel>
                            <Select label="Type" value="">
                                {types.map(type => (
                                    <MuiMenuItem key={type.id} value={type.name}>
                                        {type.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Pattern</InputLabel>
                            <Select label="Pattern" value="">
                                {patterns.map(pattern => (
                                    <MuiMenuItem key={pattern.id} value={pattern.name}>
                                        {pattern.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Shade</InputLabel>
                            <Select label="Shade" value="">
                                {shades.map(shade => (
                                    <MuiMenuItem key={shade.id} value={shade.name}>
                                        {shade.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Color</InputLabel>
                            <Select label="Color" value="">
                                {colors.map(color => (
                                    <MuiMenuItem key={color.id} value={color.name}>
                                        {color.name}
                                    </MuiMenuItem>
                                ))}
                            </Select>
                        </FormControl>
                                                
                        <Button sx={{ width: '20%', color: '#417690', backgroundColor: '#EDF4F7' }}>
                            Add
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
}

export default BuyersList;
