import React, { useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Button, Card, CardContent, Typography, Paper, Modal, TextField } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function Tags() {
    const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility
    const [categoryName, setCategoryName] = useState(''); // State to manage category name input
    const [rowModalOpen, setRowModalOpen] = useState(false); // State to manage row modal visibility
    const [selectedRow, setSelectedRow] = useState(null); // State to keep track of the selected row

    const columns = [
        {
            name: "tag",
            label: "Tag",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "tagCategory",
            label: "Tag Category",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "count",
            label: "Count",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => {
                    const color = value === "Active" ? "green" : "red";
                    return <span style={{ color }}>{value}</span>;
                }
            }
        },
        {
            name: "addToCategory",
            label: "Add To Category",
            options: {
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '20px' }}>
                    <IconButton color="primary" sx="center"
                        onClick={() => {
                            setSelectedRow(tableMeta.rowIndex);
                            setRowModalOpen(true);
                        }}>
                        <AddIcon style={{ color: '#417690', '&:hover': { color: '#5A8EA8' }} } />
                    </IconButton>
                    </div>
                ),
            }
        },
        {
            name: "delete",
            label: "Delete",
            options: {
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <IconButton color="secondary"
                    aria-label="delete"
                    onClick={() => {
                        // Handle delete logic here
                        console.log("Delete row:", tableMeta.rowIndex);
                    }}>
                        <DeleteIcon style={{ color: '#FF4C4C', '&:hover': { color: '#FF6B6B' }} } />
                    </IconButton>
                ),
            }
        }
    ];
    
    const data = [
        { tag: "Tag1", tagCategory: "Category1", count: 10, status: "Active" },
        { tag: "Tag2", tagCategory: "Category2", count: 20, status: "Inactive" },
        { tag: "Tag3", tagCategory: "Category3", count: 30, status: "Active" },
        { tag: "Tag4", tagCategory: "Category4", count: 40, status: "Inactive" },
    ];
    
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleFormSubmit = () => {
        console.log("Category Name:", categoryName);
        // Handle the form submission logic here (e.g., API call)
        setModalOpen(false); // Close modal after submission
    };

    const options = {
        filterType: 'checkbox',
        customToolbar: () => {
            return (
                <Button sx={{ color: '#417690', backgroundColor: '#EDF4F7' }} onClick={() => setModalOpen(true)}>
                    Add 
                </Button>
            );
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Tags"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>

            {/* Modal for assigning tag to category */}
            <Modal
                open={rowModalOpen}
                onClose={() => setRowModalOpen(false)}
                aria-labelledby="assign-tag-category"
                aria-describedby="assign-tag-to-category"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 6,
                        borderRadius: 2,
                    }}
                >
                    <Typography id="assign-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Assign Tag To Category
                    </Typography>
                    <TextField
                        label="Category Name"
                        fullWidth
                        variant="outlined"
                        // Handle category name input here if needed
                        sx={{ mb: 3 }}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#417690',
                            '&:hover': {
                                backgroundColor: '#5A8EA8',
                            },
                        }}
                        style={{ color: '#fff' }}
                        onClick={() => {
                            console.log("Assign Tag to Category for row:", selectedRow);
                            // Handle form submission logic here
                            setRowModalOpen(false); // Close modal after submission
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>

            {/* Modal for creating a new tag category */}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="create-tag-category"
                aria-describedby="create-a-new-tag-category"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600, // Increased width
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 6, // Increased padding
                        borderRadius: 2,
                    }}
                >
                    <Typography id="create-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Create Tag Category
                    </Typography>
                    <TextField
                        label="Category Name"
                        fullWidth
                        variant="outlined"
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        sx={{ mb: 3 }} 
                    />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#417690',
                            '&:hover': {
                                backgroundColor: '#5A8EA8',
                            },
                        }}
                        style={{ color: '#fff' }}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>                        
        </Container> 
    );
}

export default Tags;
