import React from 'react';
import { Grid, Card, CardContent, Typography, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(CategoryScale,  LinearScale,  PointElement,  LineElement,  Title,  Tooltip,  Legend,  ArcElement);

const data = {
    labels: ['Page A', 'Page B', 'Page C'], 
    datasets: [
        {
            label: 'PV',
            data: [2400, 1398, 9800],
            borderColor: '#8884d8',
            backgroundColor: 'rgba(136, 132, 216, 0.2)',
            fill: true,
        },
        {
            label: 'UV',
            data: [4000, 3000, 2000],
            borderColor: '#82ca9d',
            backgroundColor: 'rgba(130, 202, 157, 0.2)',
            fill: true,
        },
    ],
};

const pieData = {
    labels: ['Group A', 'Group B', 'Group C', 'Group D'],
    datasets: [
        {
            data: [400, 300, 300, 200],
            backgroundColor: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
        },
    ],
};

const columns = [
    { field: 'id', headerName: 'Order ID', width: 150 },
    { field: 'customerName', headerName: 'Customer Name', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'productId', headerName: 'Product ID', width: 150 },
    { field: 'totalCost', headerName: 'Total Cost', width: 150 },
];

const cardData = [
    { heading: 'Total Orders', percentageV: '+137', value: "150", bgcolor: '#f5f5f5', icon: 'orders' },
    { heading: 'Pending Orders', percentageV: '+37', value: "12", bgcolor: '#f5f5f5', icon: 'orders' },
    { heading: 'Total Customers', percentageV: '-17', value: "13", bgcolor: '#f5f5f5', icon: 'customers' },
    { heading: 'Prospects', percentageV: '+1', value: "14", bgcolor: '#f5f5f5', icon: 'customers' }
];

const rows = [
    { id: 1, customerName: 'John Doe', date: '2022-01-01', productId: '101', totalCost: '$100' },
    { id: 2, customerName: 'Jane Doe', date: '2022-01-02', productId: '102', totalCost: '$200' },
];

function Dashboard() {
    return (
        <Container>
            <Box sx={{ marginLeft: 10, marginTop: 2 }}>
            <Grid container spacing={3}>
                {/* Information Cards */}
                {cardData.map((card, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <CardComponent
                            key={index}
                            heading={card.heading}
                            percentageV={card.percentageV}
                            value={card.value}
                            bgcolor={card.bgcolor}
                            icon={card.icon}
                        />
                    </Grid>
                ))}

                {/* Line Chart */}
                <Grid item xs={12} sm={6}>
                    <Box sx={{ boxShadow: 3, padding: 3, borderRadius: 2, backgroundColor: '#EDF4F7', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} marginBottom={2}>
                        <Typography variant="h6" component="div" sx={{ marginBottom: 3 }}>
                            Month wise orders
                        </Typography>                    
                    <div style={{ width: '100%', height: 400 }}>
                        <Line data={data} />
                    </div>
                    </Box>
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={12} sm={6}>
                    <Box sx={{ boxShadow: 3, padding: 3, borderRadius: 2, backgroundColor: '#EDF4F7', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} marginBottom={2}>
                        <Typography variant="h6" component="div" sx={{ marginBottom: 3 }}>
                            Location wise orders
                        </Typography>                    
                    <div style={{ width: '80%', height: 400 }}>
                        <Pie data={pieData} />
                    </div>
                    </Box>
                </Grid>

                {/* Table */}
                <Grid item xs={12}>
                    <Box sx={{ boxShadow: 3, padding: 2, borderRadius: 2, backgroundColor: '#EDF4F7' }} marginBottom={2}>
                        <Typography variant="h6" component="div">
                            Recent Orders
                        </Typography>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                        />
                    </div>
                    </Box>
                </Grid>
            </Grid>
            </Box>
        </Container>
    );
}

export default Dashboard;
