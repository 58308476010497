import React, { useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Button, Card, CardContent, Typography, Paper, IconButton, Modal, TextField, Box, TableCell, Container } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import DeleteIcon from '@mui/icons-material/Delete';

const columns = [
    {
        name: "tagCategoryName",
        label: "Tag Category Name",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "createdDate",
        label: "Created Date",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value, tableMeta, updateValue) => {
                const color = value === "Active" ? "green" : "red";
                return <span style={{ color }}>{value}</span>;
            }
        }
    },
    {
        name: "delete",
        label: "Delete",
        options: {
            filter: false,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value, tableMeta, updateValue) => (
                <IconButton
                    aria-label="delete"
                    onClick={() => {
                        // Handle delete logic here
                        console.log("Delete row:", tableMeta.rowIndex);
                    }}
                >
                    <DeleteIcon style={{ color: '#FF4C4C', '&:hover': { color: '#FF6B6B' }} }/>
                </IconButton>
            )
        }
    },
];

const data = [
    { tagCategoryName: "Category1", createdDate: "2023-08-01", status: "Active" },
    { tagCategoryName: "Category2", createdDate: "2023-09-15", status: "Inactive" },
    { tagCategoryName: "Category3", createdDate: "2023-10-10", status: "Active" },
    { tagCategoryName: "Category4", createdDate: "2023-11-20", status: "Inactive" },
];

function TagCategory() {
    const [modalOpen, setModalOpen] = useState(false); 
    const [categoryName, setCategoryName] = useState(''); 

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleFormSubmit = () => {
        console.log("Category Name:", categoryName);
        // Handle the form submission logic here (e.g., API call)
        setModalOpen(false); // Close modal after submission
    };

    const options = {
        filterType: 'checkbox',
        customToolbar: () => {
            return (
                <Button onClick={() => setModalOpen(true)} sx={{ color: '#417690', backgroundColor: '#EDF4F7' }}>
                    Add 
                </Button>
            );
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Tag Category"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>
            
            {/* Modal for creating a new tag category */}
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="create-tag-category"
                aria-describedby="create-a-new-tag-category"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="create-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Create Tag Category
                    </Typography>
                    <TextField
                        label="Category Name"
                        fullWidth
                        variant="outlined"
                        value={categoryName}
                        onChange={handleCategoryNameChange}
                        sx={{ mb: 3 }} 
                    />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
}

export default TagCategory;
