import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, Divider, TextField, Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Example data for the chart
const sampleData = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];

const buttonStyle = {
    background: 'linear-gradient(to right, #e1dc15, #c86dd7)',
    color: '#fff',
    '&:hover': {
        background: 'linear-gradient(to right, #c86dd7, #e1dc15)',
    }
};

function UpdateLiveData() {
    const [data, setData] = useState(sampleData);
    const [inputValue, setInputValue] = useState('');

    const handleUpdate = () => {
        const newData = data.map((item) => ({
            ...item,
            uv: item.uv + (Math.random() * 1000 - 500),
        }));
        setData(newData);
    };

    // Transform data for Chart.js
    const chartData = {
        labels: data.map(item => item.name),
        datasets: [
            {
                label: 'UV',
                data: data.map(item => item.uv),
                borderColor: '#8884d8',
                backgroundColor: 'rgba(136, 132, 216, 0.2)',
                fill: true,
            },
            {
                label: 'PV',
                data: data.map(item => item.pv),
                borderColor: '#82ca9d',
                backgroundColor: 'rgba(130, 202, 157, 0.2)',
                fill: true,
            },
        ],
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 0 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
                    <Typography variant="h4" gutterBottom>
                        Update Live Data
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Update Data
                            </Typography>
                            <TextField
                                fullWidth
                                label="Enter New Value"
                                variant="outlined"
                                margin="normal"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={buttonStyle}
                                    onClick={handleUpdate}
                                >
                                    Update Data
                                </Button>
                            </Box>
                            <Divider sx={{ margin: '20px 0' }} />
                            <Typography variant="h6" gutterBottom>
                                Data Visualization
                            </Typography>
                            <Box sx={{ height: 300 }}>
                                <Line data={chartData} />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Container>
    );
}

export default UpdateLiveData;
