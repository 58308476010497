import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Dialog, DialogContent, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';

const productTypes = [
    { name: 'Checks', image: '/images/checks.jpg', products: 120 },
    { name: 'Denim', image: '/images/denim.jpg', products: 80 },
    { name: 'Plain', image: '/images/plain.jpg', products: 150 },
    { name: 'Print', image: '/images/print.jpg', products: 200 },
    { name: 'Stripes', image: '/images/stripes.jpg', products: 90 },
];

const dummyProducts = [
    { id: '001', category: 'Category A', quantity: 50 },
    { id: '002', category: 'Category B', quantity: 30 },
    { id: '003', category: 'Category C', quantity: 20 },
];

const ProductListTable = ({ product, onClose }) => (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: 'absolute', top: 8, right: 8 }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Product List
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
                Total Products: {product.products}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product ID</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Net Quantity (In Stock)</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dummyProducts.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.category}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        component="label"
                                    >
                                        <UploadIcon />
                                        <input type="file" hidden />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton color="secondary">
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
    </Dialog>
);

function ProductCatalogue() {
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleCardClick = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseDialog = () => {
        setSelectedProduct(null);
    };

    return (
        <Container
            sx={{
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#EDF4F7',
                minHeight: '100vh',
                fontFamily: '"Roboto Condensed", sans-serif',
                borderRadius: '8px',
                boxShadow: 4,
                marginLeft: 10,
                marginTop: 2,
            }}
        >
            <Typography 
                variant="h4" 
                sx={{ 
                    fontSize: '1.25rem', 
                    textTransform: 'uppercase', 
                    fontFamily: '"Roboto Condensed", sans-serif',
                    letterSpacing: 1.2,
                    fontWeight: 400,
                    marginTop: 2,
                    marginBottom: 2,
                    alignSelf: 'flex-start'
                }}
            >
                Product Catalogue
            </Typography>

            <Grid container spacing={2} sx={{ marginTop: 4 }}>
                {productTypes.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            sx={{
                                height: 180,
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                fontFamily: '"Roboto Condensed", sans-serif',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleCardClick(product)}
                        >
                            <CardMedia
                                component="img"
                                alt={product.name}
                                height="100"
                                image={product.image}
                                sx={{
                                    height: '100px',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            <CardContent
                                sx={{
                                    padding: 1,
                                    fontSize: '0.75rem',
                                    fontFamily: '"Roboto Condensed", sans-serif'
                                }}
                            >
                                <Typography 
                                    variant="h6" 
                                    component="div"
                                    sx={{
                                        fontSize: '1rem',
                                        fontFamily: '"Roboto Condensed", sans-serif'
                                    }}
                                >
                                    {product.name}
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    color="text.secondary"
                                    sx={{ 
                                        fontSize: '0.75rem', 
                                        fontFamily: '"Roboto Condensed", sans-serif'
                                    }}
                                >
                                    Products: {product.products}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {selectedProduct && (
                <ProductListTable
                    product={selectedProduct}
                    onClose={handleCloseDialog}
                />
            )}
        </Container>
    );
}

export default ProductCatalogue;
